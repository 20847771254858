import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'preview', 'template', 'browse'];

    static values = {
        preview: Boolean,
    };

    multiple = false;

    files = {};

    connect() {
        this.inputTarget.addEventListener('change', (event) =>
            this.onInputChange(event),
        );
        this.multiple = this.inputTarget.hasAttribute('multiple');
        this.hasTemplate = this.hasTemplateTarget;

        if (this.hasTemplate) {
            this.template = this.templateTarget.content;
        }

        if (this.inputTarget.dataset.fileUrl && this.previewValue) {
            this.#renderExistingFilePreview(this.inputTarget.dataset.fileUrl);
        }
    }

    /** @param {HTMLElement} element */
    inputTargetConnected(element) {
        element.addEventListener('dragenter', (event) => {
            if (event.target.classList.contains('dropzone-input')) {
                event.target.classList.add('dragover');
            }
        });

        element.addEventListener('dragleave', (event) => {
            if (event.target.classList.contains('dropzone-input')) {
                event.target.classList.remove('dragover');
            }
        });
    }

    onInputChange(event) {
        this.#addFiles(event.target.files);
        this.#updateInput();
        this.#renderPreview();
        if (false === this.multiple) {
            this.#showHideBrowse();
        }
    }

    removeFile({ params: { id } }) {
        if (!this.files.hasOwnProperty(id)) {
            return;
        }

        delete this.files[id];

        this.#updateInput();
        this.#renderPreview();
        if (false === this.multiple) {
            this.#showHideBrowse();
        }
    }

    #clearPreview() {
        this.previewTarget.innerHTML = '';
    }

    #renderExistingFilePreview(fileUrl) {
        const fileName = fileUrl.split('/').pop();
        const id = 'existing-file';

        this.files[id] = { name: fileName, file: fileUrl, id: id };
        this.#renderPreview();
        if (false === this.multiple) {
            this.#showHideBrowse();
        }
    }

    #renderPreview() {
        this.#clearPreview();

        for (const id in this.files) {
            this.#renderFile(this.files[id]);
        }
    }

    #renderFile(fileObj) {
        if (!this.hasTemplate) {
            return false;
        }

        const template = this.template.cloneNode(true);
        const filenameContainer = template.querySelector(
            '.dropzone-preview-filename',
        );
        const fileContainer = template.querySelector('.dropzone-preview-file');
        const deleteButton = template.querySelector('.dropzone-delete-button');

        const fileName = fileObj.name.split('.');
        const fileExt = fileName.pop().split('?')[0];

        filenameContainer.innerHTML = `<span class="fileupload-filename text-truncate">${fileName}</span><span class="fileupload-fileext text-truncate">.${fileExt}</span>`;
        filenameContainer.setAttribute('title', `${fileName}.${fileExt}`);

        if (this.previewValue) {
            const img = document.createElement('img');
            if (this.#isPreviewable(fileObj.file)) {
                if (typeof fileObj.file === 'string') {
                    img.src = fileObj.file;
                } else if (fileObj.file instanceof File) {
                    img.src = URL.createObjectURL(fileObj.file);
                }
            }
            if (img.src) {
                img.setAttribute('title', fileObj.name);
                img.setAttribute('alt', fileObj.name);
                fileContainer.prepend(img);
            }
        }

        deleteButton.setAttribute(
            'data-form--file-upload-id-param',
            fileObj.id,
        );

        this.previewTarget.appendChild(template);
    }

    #isPreviewable(file) {
        if (file instanceof File) {
            return file.type.startsWith('image/');
        }
        if (typeof file === 'string') {
            const url = new URL(file);
            const pathname = url.pathname;
            const filename = pathname.substring(pathname.lastIndexOf('/') + 1);

            return /\.(jpeg|jpg|gif|png|webp|bmp)$/i.test(filename);
        }
        return false;
    }

    #addFiles(files) {
        for (const file of files) {
            this.#addFile(file);
        }
    }

    #addFile(file) {
        if (false === this.multiple) {
            this.#resetFiles();
        }

        const id = crypto.randomUUID();
        this.files[id] = { name: file.name, file: file, id: id };

        this.inputTarget.classList.remove('dragover');
    }

    #resetFiles() {
        this.files = {};
    }

    #updateInput() {
        const dt = new DataTransfer();

        for (const id in this.files) {
            const fileObj = this.files[id];
            if (fileObj.file instanceof File) {
                dt.items.add(fileObj.file);
            }
        }

        this.inputTarget.files = dt.files;
    }

    #showHideBrowse() {
        if (bindhq.isGranted('FEATURE_NEW_SIGNATURE_AVATAR')) {
            this.browseTarget.classList.toggle(
                'd-none',
                Object.keys(this.files).length !== 0,
            );
        }
    }
}
