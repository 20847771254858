import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    selectedFinanceCompanies = new Set();

    static targets = ['financeCompany', 'container', 'warning'];

    connect() {
        $(this.financeCompanyTarget).on(
            'change',
            this.#onFinanceCompanyChange.bind(this),
        );
        $(this.containerTarget).on('click', (e) => {
            if (
                e.target.matches(
                    'input[type="checkbox"][data-finance-company-id]',
                )
            ) {
                this.#onFinanceCompanySelect(e);
            }
        });
    }

    #onFinanceCompanySelect(event) {
        if (this.selectedFinanceCompanies.has(event.target)) {
            this.selectedFinanceCompanies.delete(event.target);
        } else {
            this.selectedFinanceCompanies.add(event.target);
        }

        this.#onFinanceCompanyChange();
    }

    #onFinanceCompanyChange() {
        const financeCompany =
            parseInt($(this.financeCompanyTarget).val()) || null;

        const selectedIds = new Set(
            Array.from(this.selectedFinanceCompanies).map(
                (company) => parseInt(company.dataset.financeCompanyId) || null,
            ),
        );

        // If no finance company is selected
        if (null === financeCompany) {
            if (selectedIds.size > 1) {
                this.warningTarget.classList.remove('d-none');
                this.warningTarget.dataset.content =
                    'The selected Policies are financed by different Finance Companies.';
            } else {
                this.warningTarget.classList.add('d-none');
                this.warningTarget.dataset.content = '';
            }
        } else {
            // Remove 'null', and the selected finance company from the selected set
            // If the selected set is not empty, then there are other finance companies selected
            selectedIds.delete(null);
            selectedIds.delete(financeCompany);

            if (selectedIds.size > 0) {
                this.warningTarget.classList.remove('d-none');
                this.warningTarget.dataset.content =
                    'Some of the selected Policies are financed by a different Finance Company then your selection.';
            } else {
                this.warningTarget.classList.add('d-none');
                this.warningTarget.dataset.content = '';
            }
        }
    }
}
