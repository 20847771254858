import { Controller } from '@hotwired/stimulus';
import { AjaxSubmitHandler } from '../../js/BindHQ/Form/AjaxSubmitHandler';

export default class extends Controller {
    static values = {
        title: {
            type: String,
            default: 'Confirm',
        },
        message: String,
        cancel: String,
        confirm: {
            type: String,
            default: 'Are you sure?',
        },
        confirmType: {
            type: String,
            default: 'danger',
        },
        usesAjax: {
            type: Boolean,
            default: false,
        },
    };

    connect() {
        if (null === this.element.closest('form')) {
            throw new Error(
                'The confirm controller can only be used on an element within a form.',
            );
        }

        if (this.usesAjaxValue) {
            this.ajaxSubmitHandler = new AjaxSubmitHandler(
                $(this.element.closest('form')),
                this.closeModal,
                this.closeModal,
            );
        }
    }

    /**
     * By default the jQuery form plugin doesn't include the submit button in the
     * form data. Also, the form does not include the button field unless the button was clicked
     * so here we include it as a hidden item if it's defined a name.
     */
    appendButton(element) {
        if (
            'undefined' === typeof element ||
            'BUTTON' !== element.tagName ||
            null === element.name
        ) {
            return;
        }

        const button = element;
        const form = button.closest('form');
        const appendButtonId = `confirm-button-append-${button.name}`;

        if (null === form || null !== document.getElementById(appendButtonId)) {
            return;
        }

        const input = document.createElement('input');
        input.type = 'hidden';
        input.id = appendButtonId;
        input.name = button.name;
        input.value = button.value;
        form.appendChild(input);
    }

    onClick(event) {
        event.stopPropagation();
        event.preventDefault();

        this.appendButton(this.element);

        window.dispatchEvent(
            new CustomEvent('confirm--button:clicked', {
                detail: {
                    title: this.titleValue,
                    body: this.messageValue,
                    cancel: this.cancelValue,
                    confirm: this.confirmValue,
                    confirmType: this.confirmTypeValue,
                    onConfirm: () => {
                        if (this.usesAjaxValue) {
                            this.ajaxSubmitHandler.onClick(event);
                        } else {
                            this.element.closest('form').submit();
                        }
                    },
                    lockOnConfirm: true,
                },
            }),
        );
    }

    closeModal() {
        window.dispatchEvent(new CustomEvent('confirm--dialog:closeDialog'));
    }
}
