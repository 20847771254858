import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
        message: String,
    };

    onClick(event) {
        event.stopPropagation();
        event.preventDefault();

        if (!this.messageValue) {
            this.#post();
        }

        window.dispatchEvent(
            new CustomEvent('confirm--button:clicked', {
                detail: {
                    title: 'Confirm',
                    body: this.messageValue,
                    confirm: 'Are you sure?',
                    confirmType: 'danger',
                    onConfirm: () => {
                        this.#post();
                    },
                    lockOnConfirm: true,
                },
            }),
        );
    }

    #post() {
        bindhq.util.post(this.urlValue);
    }
}
