import { Controller } from '@hotwired/stimulus';

const BrokerageTemplating = require('../../assets/js/BindHQ/Utils/BrokerageTemplating');
const CONTACTS_PER_PAGE = 50;

let contactsCurrentPage;
let contactsCurrentFilter;
let contactsTotalPages;

export default class extends Controller {
    static values = {
        previewTemplateUrl: String,
    };

    /**
     *
     */
    connect() {
        const container = $(this.element);

        function setMcState(row) {
            const marketingCompanyId = row.data('marketingCompanyId');

            if (row.data('state') === 'open') {
                container
                    .find(
                        `.brokerage-marketing-company-${marketingCompanyId}.visible`,
                    )
                    .show();
                row.findOne('.sort-toggler-down').show();
                row.findOne('.sort-toggler-up').hide();
            } else {
                container
                    .find(`.brokerage-marketing-company-${marketingCompanyId}`)
                    .hide();
                row.findOne('.sort-toggler-down').hide();
                row.findOne('.sort-toggler-up').show();
            }
        }

        container.find('.brokerage-marketing-company-contact').hide();
        container.find('.sort-toggler-down').hide();
        // toggle contacts on marketing company click click
        container.find('.brokerage-marketing-company').click(function (e) {
            const row = container.findOne(e.target).closest('tr');
            row.data('state', row.data('state') === 'open' ? 'closed' : 'open');
            setMcState(row);
        });

        // The email templates can be selected by the user. If there is only 1 available,
        // it will be preselected.
        BrokerageTemplating.configureEmailTemplates(
            container,
            this.previewTemplateUrlValue,
        );

        // handle the filtering in JavaScript.
        // This means we load all marketing company contacts into the HTML and then
        // filter them in JavaScript, so the response is instantaneous and doesn't require
        // an API endpoint, server side queries, etc

        contactsCurrentPage = 0;
        contactsCurrentFilter = null;
        contactsTotalPages = Math.round(
            $('#brokerage-markets-and-contacts tbody tr').length /
                CONTACTS_PER_PAGE,
        );

        const contactsContainer = $(
            '.marketing-company-contacts-container',
            container,
        );

        const refreshContacts = function () {
            BrokerageTemplating.filter(
                container,
                contactsCurrentFilter,
                contactsCurrentPage,
                CONTACTS_PER_PAGE,
            );

            contactsContainer.get(0).scrollTop = 0;
        };

        container
            .find('#brokerage-manager-contact-filter')
            .on('input propertychange paste', function (e) {
                contactsCurrentPage = 0;
                contactsCurrentFilter = e.target.value.trim().toLowerCase();

                refreshContacts();

                // open all visible search results; close others
                container
                    .find('.brokerage-marketing-company')
                    .each((i, row) => {
                        const $row = $(row);
                        $row.data(
                            'state',
                            $row.hasClass('visible') ? 'open' : 'closed',
                        );
                        setMcState($row);
                    });
            });

        container.on('click', '.btn-pagination-previous', function () {
            if (contactsCurrentPage > 0) {
                contactsCurrentPage--;
                refreshContacts();
            }
        });

        container.on('click', '.btn-pagination-next', function () {
            if (contactsCurrentPage < contactsTotalPages - 1) {
                contactsCurrentPage++;
                refreshContacts();
            }
        });

        if (0 !== contactsContainer.length) {
            refreshContacts();
        }

        this.updateRightHandColumn();

        container.find('#brokerage-manager-loading').hide();
        container.find('#brokerage-manager').show();
    }

    /**
     * @param {Event} evt
     */
    onMarketingCompanyContactClick(evt) {
        this.updateRightHandColumn();
    }

    updateRightHandColumn() {
        BrokerageTemplating.updateRightHandColumn(
            $(this.element),
            this.previewTemplateUrlValue,
        );
    }
}
