import { Controller } from '@hotwired/stimulus';

const { CollectionIndexer } = require('../../js/BindHQ/Form/CollectionIndexer');

const {
    LineItemRegenerator,
    LineItemRegeneratorEvents,
} = require('../../js/BindHQ/Form/LineItemRegenerator');

const {
    FormFlowReloader,
    FormFlowReloaderEvents,
} = require('../../js/BindHQ/Form/FormFlowReloader');

const {
    TriaToggler,
    TriaTogglerEvents,
} = require('../../js/BindHQ/Quote/TriaToggler');

const {
    ManualTaxesToggler,
    ManualTaxesTogglerEvents,
} = require('../../js/BindHQ/Quote/ManualTaxesToggler');

const {
    BillingTypeListener,
    BillingTypeListenerEvents,
} = require('../../js/BindHQ/Quote/BillingTypeListener');

export default class extends Controller {
    static values = {
        premiumLineItemType: String,
        mgaFeeLineItemType: String,
        carrierFeeLineItemType: String,
        directBillBillingType: String,
        directBillMgaFeeEnabled: Boolean,
    };

    connect() {
        const container = $(this.element);
        const formFlowReloader = new FormFlowReloader(container);
        const lineItemRegenerator = new LineItemRegenerator(container);
        const triaToggler = new TriaToggler(container);
        const collectionIndexer = new CollectionIndexer();
        const manualTaxesToggler = new ManualTaxesToggler(
            container,
            collectionIndexer,
        );

        const billingTypeListener = new BillingTypeListener(
            container,
            this.premiumLineItemTypeValue,
            this.mgaFeeLineItemTypeValue,
            this.carrierFeeLineItemTypeValue,
            this.directBillBillingTypeValue,
            this.directBillMgaFeeEnabledValue,
        );

        lineItemRegenerator.subscribe(LineItemRegeneratorEvents.CHANGED, () =>
            formFlowReloader.reload(),
        );
        lineItemRegenerator.subscribe(LineItemRegeneratorEvents.KEYUP, () =>
            formFlowReloader.reload(),
        );
        triaToggler.subscribe(TriaTogglerEvents.CHANGED, () =>
            formFlowReloader.reloadNow(),
        );
        triaToggler.subscribe(TriaTogglerEvents.KEYUP, () =>
            formFlowReloader.reload(),
        );
        manualTaxesToggler.subscribe(ManualTaxesTogglerEvents.CHANGED, () =>
            formFlowReloader.reloadNow(),
        );
        billingTypeListener.subscribe(BillingTypeListenerEvents.CHANGED, () =>
            formFlowReloader.reloadNow(),
        );

        formFlowReloader.subscribe(FormFlowReloaderEvents.CHANGED, (xhr) => {
            lineItemRegenerator.render(xhr);
            triaToggler.render(xhr);
        });

        lineItemRegenerator.init();
        triaToggler.init();
        manualTaxesToggler.init();
        billingTypeListener.init();
    }
}
