const numeral = require('numeral');

/**
 * @param {jQuery} elem
 * @param {string} type
 */
function syncHeader(elem, type) {
    const id = elem.data('target') || elem.attr('id');
    const target = `#${type}-${id}`;

    let value = '',
        data = null;

    switch (type) {
        case 'premium-basis':
            value = elem.get(0).options[elem.get(0).selectedIndex].innerHTML;

            syncHeader(
                elem.closest('div.row').find('input.classification-exposure'),
                'exposure',
            );
            break;

        case 'class-code':
            data = elem.select2('data');
            if (null !== data) {
                value = data.name + ' - ' + data.iso_code;
            }
            break;

        case 'exposure':
            data = elem.select2('data');
            value = data[0] ? data[0].value.trim() : '';
            if (value.length > 0) {
                value =
                    elem
                        .closest('div')
                        .find('.sign')
                        .text()
                        .replace(/[()]/g, '') + value;
            }
            break;

        case 'premium':
            value = '~';
            if (elem.is(':checked')) {
                value = '';
                break;
            }
            let total = 0;
            elem.closest('table.classification-coverages')
                .find('input.classification-premium:not(:hidden)')
                .each(function () {
                    total += numeral($(this).val());
                });
            if (total > 0) {
                value = numeral(total).format('$0,0.00');
            }
            break;

        case 'if-any':
            if (elem.is(':checked')) {
                value = 'If any ';
            }
            syncHeader(
                elem
                    .closest('.classification')
                    .find('.classification-premium-basis'),
                'premium-basis',
            );
            break;
    }

    $(target).html(value);
}

module.exports = {
    syncHeader,
};
