const Observable = require('../Observable');

const { CollectionIndexer } = require('./CollectionIndexer');

const CollectionEvents = {
    ADDED: 'bindhq.form.collection.added',
    REMOVED: 'bindhq.form.collection.removed',
};

const initLazyStimulus = require('./LazyStimulus');

class Collection extends Observable {
    /**
     * @param {HTMLElement} element
     * @param {Boolean} reindex Should the collection reindex itself?
     */
    constructor(element, reindex) {
        super();

        this.element = element;
        this.reindex = reindex;
        this.prototype = element.querySelector('[data-prototype]');
        this.collectionIndexer = new CollectionIndexer();
        this.index = this.prototype ? this.prototype.childNodes.length : 1;
    }

    /**
     * @param {Event} evt
     */
    add(evt) {
        evt.preventDefault();

        if (!this.prototype) {
            throw new Error('Cannot add to collection with no prototype');
        }

        const nextIndex = this.reindex
            ? this.collectionIndexer.reindex($(this.element))
            : this.index++;

        let html = this.prototype.dataset.prototype.replace(
            '__index__',
            $('> .collection-item', this.prototype).length + 1,
        );

        const matches = html.match(/[\w[\]]+__name__[\w+[\]"]+/g);

        if (null !== matches) {
            matches
                .sort()
                .reverse()
                .forEach((match) => {
                    html = html.replace(
                        match,
                        match.replace('__name__', nextIndex),
                    );
                });
        }

        const element = document.createElement('div');
        element.innerHTML = html;

        this.addItem(element.firstElementChild);
    }

    /** @param {HTMLElement} item */
    addItem(item) {
        this.prototype.appendChild(item);

        initLazyStimulus(item, true);

        this.#fireEvent(CollectionEvents.ADDED, { newElement: item });
    }

    /**
     * @param {Event} evt
     */
    delete(evt) {
        evt.preventDefault();

        if (!this.prototype) {
            throw new Error('Cannot delete from collection with no prototype');
        }

        const selector = '.collection-item';
        const item = evt.target.closest(selector);

        if (null === item) {
            throw new Error(
                'Cannot find item container to delete with selector: ' +
                    selector,
            );
        }

        item.parentNode.removeChild(item);

        this.#fireEvent(CollectionEvents.REMOVED, { removedElement: item });
    }

    /**
     * @param {String} type
     * @param {Object} params
     */
    #fireEvent(type, params) {
        super.fire(type, params);

        this.element.dispatchEvent(
            new CustomEvent(type, {
                bubbles: true,
                detail: {
                    element: this.element,
                    args: params,
                },
            }),
        );
    }
}

module.exports = { Collection, CollectionEvents };
