import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'financeData',
        'financed',
        'createFinanceReceivable',
        'finance',
        'financeLabel',
        'financedAmount',
        'financedAmountLabel',
    ];

    connect() {
        this.financed();
    }

    financed() {
        if (this.financedTarget.checked) {
            this.#isFinanced();
        } else {
            this.#isNotFinanced();
            this.createFinanceReceivableTarget.checked = false;
            this.#shouldNotCreateFinanceReceivable();
        }
    }

    createFinanceReceivable() {
        if (this.createFinanceReceivableTarget.checked) {
            this.#shouldcreateFinanceReceivable();
        } else {
            this.#shouldNotCreateFinanceReceivable();
        }
    }

    #isFinanced() {
        this.financeDataTarget.classList.remove('d-none');
        this.financeTarget.setAttribute('required', 'required');
        this.financeLabelTarget.classList.add('required');
    }

    #isNotFinanced() {
        this.financeDataTarget.classList.add('d-none');
        this.financeTarget.removeAttribute('required');
        this.financeLabelTarget.classList.remove('required');
    }

    #shouldcreateFinanceReceivable() {
        this.financedAmountTarget.setAttribute('required', 'required');
        this.financedAmountLabelTarget.classList.add('required');
    }

    #shouldNotCreateFinanceReceivable() {
        this.financedAmountTarget.removeAttribute('required');
        this.financedAmountLabelTarget.classList.remove('required');
    }
}
