(function () {
    'use strict';

    const DEBOUNCE_MILLIS = 500;

    bindhq.ns('forms.ajaxselect');

    bindhq.forms.ajaxselect.PER_PAGE = 50;

    bindhq.forms.ajaxselect.itemName = function (item) {
        const noName = '(no name)';

        return item ? item.name || noName : noName;
    };

    bindhq.forms.ajaxselect.formatSelection = function (select, item) {
        const format = select.data('format');

        return format
            ? bindhq.util.template(format, { item: item })
            : bindhq.forms.ajaxselect.itemName(item);
    };

    bindhq.forms.ajaxselect.formatResult = function (select, type, item) {
        const format = select.data('result-format');

        /* eslint-disable indent */
        return format
            ? bindhq.util.template(format, { item: type })
            : bindhq.util.template(
                  bindhq.forms.ajaxselect.formatSelection(select, type, item),
              );
        /* eslint-enable indent */
    };

    /**
     * Get item ID as a string
     *
     * @param {{object}} item
     * @returns {string}
     */
    bindhq.forms.ajaxselect.idToString = function (item) {
        item.id = String(item.id);
        return item;
    };

    /**
     * @param {HTMLElement} container
     * @param {string} acceptNew
     * @param {array} result
     *
     * @returns {object}
     */
    bindhq.forms.ajaxselect.results = function (
        container,
        acceptNew,
        acceptNewText,
        result,
    ) {
        const resultProperty = container.data('result-property');
        const items = resultProperty ? result[0][resultProperty] : result;
        const more = items.length >= bindhq.forms.ajaxselect.PER_PAGE;
        const select2 = $(container).data('select2');
        const value = select2.search.val();

        if (acceptNew && value) {
            items.push({
                id: 'new',
                name: acceptNewText.replaceAll('{{ value }}', value),
                cleanName: value,
            });
        }

        return {
            results: _.map(items, bindhq.forms.ajaxselect.idToString),
            more,
        };
    };

    bindhq.forms.ajaxselect.eventFirer = function (container, items) {
        bindhq.util.fireEvent(container, 'dataLoaded', items);
        return items;
    };

    /**
     * @param {String} keys
     * @param {String} schedule
     * @param {boolean} multiple
     * @param {DOMElement} element
     * @param {Function} callback
     */
    bindhq.forms.ajaxselect.initItem = function (
        keys,
        schedule,
        multiple,
        element,
        callback,
    ) {
        const item = $(element);
        const id = item.val();
        const initial = item.data('initial');

        if (initial) {
            callback(initial);

            if (typeof initial.id !== 'undefined') {
                item.select2('val', initial.id);
            }
        } else {
            const callWithFirst = function (data) {
                if (data.length > 0) {
                    callback(data[0]);
                }
            };
            const onSuccess = multiple ? callback : callWithFirst;

            const data = {};
            data[keys] = id;

            const config = {
                url: bindhq.forms.ajaxselect.getUrl(item),
                data: data,
                dataType: 'json',
                success: onSuccess,
            };

            bindhq.util.ajax(config);
        }
    };

    /**
     * @param {jQuery} select
     * @param {Array} items
     *
     * return Array
     */
    bindhq.forms.ajaxselect.prependOptional = function (select, items) {
        const isOptional = select.data('optional') === '1';
        const optionalText = select.data('optional-text');
        const optional = {
            id: '',
            name: optionalText,
        };

        return isOptional ? [optional].concat(items) : items;
    };

    /**
     * Create a tag to prepend to a list of items
     *
     * @param {{string}} val
     * @param {{jQuery}} select
     * @returns {Array}
     */
    bindhq.forms.ajaxselect.prependEntryToItems = function (val, select) {
        if (val !== '' && select.data('tags') === '1') {
            return [
                {
                    email: val,
                    name: val,
                },
            ];
        }

        return [];
    };

    bindhq.forms.ajaxselect.createTag = function (select) {
        return select.data('multiple')
            ? $(select).parent().find('.select2-input').val()
            : $('.select2-drop-active .select2-input').val();
    };

    bindhq.forms.ajaxselect.prependTag = function (select, items) {
        const val = bindhq.forms.ajaxselect.createTag(select);
        const tag = bindhq.forms.ajaxselect.prependEntryToItems(val, select);

        return tag.concat(items);
    };

    bindhq.forms.ajaxselect.getParams = function (type) {
        const defaultParams = { size: bindhq.forms.ajaxselect.PER_PAGE };

        return type !== ''
            ? $.extend(defaultParams, { type: type })
            : defaultParams;
    };

    bindhq.forms.ajaxselect.getType = function (select) {
        return select.data('type') || '';
    };

    bindhq.forms.ajaxselect.getKeys = function (select) {
        return select.data('id') || 'id';
    };

    /**
     * @param {jQuery} container
     *
     * @return {String}
     */
    bindhq.forms.ajaxselect.getUrl = function (container) {
        const ajaxUrl = container.data('ajaxurl');

        if (ajaxUrl) {
            return typeof ajaxUrl === 'function' ? ajaxUrl() : ajaxUrl;
        } else {
            if (window.console && console.warn) {
                console.warn('Deprecated usage of select2 ajax endpoint');
            }

            return '/secure/admin/' + container.data('schedule') + '/all.json';
        }
    };

    /**
     * Update a child controls state to repect that of its owner
     *
     * @param {jQuery} owner
     * @param {jQuery} child
     * @param {Function} callback
     */
    bindhq.forms.ajaxselect.updateChild = function (owner, child, callback) {
        const value = owner.select2('val');

        if (value && !owner.attr('disabled')) {
            child.select2('enable');
            callback(owner, child, value);
        } else {
            child.select2('disable');
        }
    };

    /**
     * Handler for when a dependent child control needs to be updated
     * because of a change in its parent.
     *
     * @param {jQuery} owner
     * @param {jQuery} child
     * @param {Function} callback
     */
    bindhq.forms.ajaxselect.ownerChanged = function (owner, child, callback) {
        bindhq.forms.ajaxselect.updateChild(owner, child, callback);

        child.select2('val', null, true);
    };

    /**
     * Binds two ajax-selects together, one being dependent on the value of
     * the other.  When a value from the first is selected the callback is fired
     * for the second to set themselves up.
     *
     * @param {jQuery} owner
     * @param {jQuery} child
     * @param {Function} callback
     */
    bindhq.forms.ajaxselect.dependsOn = function (owner, child, callback) {
        const ownerChanged = _.partial(
            bindhq.forms.ajaxselect.ownerChanged,
            owner,
            child,
            callback,
        );

        owner.on('change', ownerChanged);

        bindhq.forms.ajaxselect.updateChild(owner, child, callback);
    };
    /**
     * Select all or clear all the items, depending on if the select all checkbox is ticked
     *
     * @param {{object}} e
     */
    bindhq.forms.ajaxselect.selectAllChange = function (e) {
        const target = $(e.currentTarget);
        const input = e.data.input;

        if (target.prop('checked') === true) {
            // Set all the options to selected. Dunno how, but this works...
            input.select2('val', [], true);
        } else {
            input.select2('val', '', true);
        }
    };

    bindhq.forms.ajaxselect.setUpSelectAll = function (container) {
        if (container.attr('data-select-all') === '1') {
            const selectAll = $(
                '<div class="select-all"><input type="checkbox" id="select-all" /> <label for="select-all">Select all</label></div>',
            );
            selectAll
                .find('input')
                .change(
                    { input: container },
                    bindhq.forms.ajaxselect.selectAllChange,
                );
            container.before(selectAll);
        }
    };

    bindhq.forms.ajaxselect.tagger = function (term, data) {
        const fltr = function (item) {
            return item.name && item.name.localeCompare(term) === 0;
        };

        if (data.filter(fltr).length === 0) {
            return {
                id: term,
                name: term,
            };
        }
    };

    bindhq.forms.ajaxselect.onClearClicked = function (container) {
        container.select2('data', null);
        container.trigger('change');
    };

    bindhq.forms.ajaxselect.initClearer = function (container) {
        const onClick = _.partial(
            bindhq.forms.ajaxselect.onClearClicked,
            container,
        );

        const s2container = container.siblings('.select2-container');

        $('<a></a>')
            .html('⨯')
            .attr('href', '#')
            .addClass('select2-clearer')
            .click(onClick)
            .appendTo(s2container);
    };

    bindhq.forms.ajaxselect.filterFrom = function (container) {
        const filter = container.data('filter');

        return _.isString(filter) ? JSON.parse(filter) : filter;
    };

    bindhq.forms.ajaxselect.initContainer = function (container, options) {
        const ajaxselect = bindhq.forms.ajaxselect;
        const schedule = container.data('schedule');
        const multiple = container.data('multiple');
        const acceptNew = container.data('accept-new');
        const acceptNewText =
            container.data('accept-new-text') || 'Add &quot;{{ value }}&quot;';
        const prependOptional = _.partial(
            ajaxselect.prependOptional,
            container,
        );
        const prependTag = _.partial(ajaxselect.prependTag, container);
        const eventFirer = _.partial(ajaxselect.eventFirer, container);
        const results = _.compose(
            eventFirer,
            _.partial(ajaxselect.results, container, acceptNew, acceptNewText),
            prependTag,
            prependOptional,
        );
        const type = ajaxselect.getType(container);
        const defaults = ajaxselect.getParams(type);
        const formatResult = _.partial(ajaxselect.formatResult, container);
        const formatSelection = _.partial(
            ajaxselect.formatSelection,
            container,
        );
        const keys = ajaxselect.getKeys(container);
        const initItem = _.partial(ajaxselect.initItem, keys + 's');
        const initSelection = _.partial(initItem, schedule, multiple);
        const tagging = {
            tokenSeparators: [','],
            createSearchChoice: bindhq.forms.ajaxselect.tagger,
        };

        const taggingConfig = container.data('allow-tags') ? tagging : {};

        const config = $.extend(
            {
                adaptDropdownCssClass: function (clazz) {
                    return clazz + '-dropdown';
                },
                placeholder: container.data('placeholder'),
                multiple: multiple,
                ajax: {
                    url: _.partial(ajaxselect.getUrl, container),
                    dataType: 'json',
                    data: function (term, page) {
                        const params = {
                            page: page,
                            'min-score': term ? bindhq.MIN_SEARCH_SCORE : 0,
                        };
                        const termParam = container.data('term-parameter');
                        const limit = container.data('limit');

                        params[termParam || 'name'] = term;
                        if (limit) {
                            params.limit = limit;
                        }

                        return $.extend(
                            defaults,
                            params,
                            ajaxselect.filterFrom(container),
                        );
                    },
                    quietMillis: DEBOUNCE_MILLIS,
                    params: bindhq.util.ajaxDefaults,
                    results: results,
                },
                formatResult: formatResult,
                formatSelection: formatSelection,
                initSelection: _.once(initSelection),
                id: keys,
                dropdownAutoWidth: true,
            },
            taggingConfig,
            options,
        );

        bindhq.forms.ajaxselect.setUpSelectAll(container);

        container.select2(config);
        container.on('change', function (e) {
            // revalidate the element on change
            const form = $(e.target).closest('form');

            if (0 === form.length) {
                // no form to validate
                return;
            }

            if (!form.validate) {
                return;
            }

            const validator = form.validate();

            if (validator && validator.element) {
                validator.element(e.target);
            }
        });

        if (container.data('clearable') === 'yes') {
            ajaxselect.initClearer(container);
        }
    };
})();
