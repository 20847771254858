import { Controller } from '@hotwired/stimulus';

const { TomSelectEvents } = require('../js/BindHQ/Form/TomSelect');

const Clipboard = require('clipboard');
const Sentry = require('@sentry/browser');
const SentryApm = require('@sentry/apm');
import posthog from 'posthog-js';

export default class extends Controller {
    connect() {
        const body = $('body');
        const onWindowScroll = function () {
            const scrollTop = $(document).scrollTop();
            const method = scrollTop > 100 ? 'addClass' : 'removeClass';

            body[method]('scrolled');
        };

        const userId = body.data('userid');
        const ornClient = body.data('orn-client');

        const posthogApiKey = body.data('posthog-api-key');

        if (posthogApiKey) {
            posthog.init(posthogApiKey, { api_host: 'https://us.posthog.com' });

            if (userId) {
                posthog.identify(ornClient + '-' + userId, {
                    name: body.data('user-name'),
                    email: body.data('user-email'),
                });
            }
        }

        const sentryDsn = body.data('sentry-dsn');

        if (sentryDsn) {
            const integrations = [
                new Sentry.Integrations.GlobalHandlers({
                    onerror: true,
                    onunhandledrejection: false,
                }),
            ];

            const sentryConfig = {
                dsn: sentryDsn,
                environment: body.data('sentry-environment'),
                ignoreErrors: [
                    'ResizeObserver loop limit exceeded',
                    'ResizeObserver loop completed with undelivered notifications',
                    'com.fulcrologic',
                    'gondola',
                    'taoensso.sente',
                    'select2 is not a function',
                    'Tom Select already initialized on this element',
                    /window.\w+ is not a function/,
                ],
                denyUrls: [/extensions\//i, /^chrome:\/\//i],
                integrations: integrations,
            };

            if (body.data('sentry-apm-enabled')) {
                sentryConfig.integrations.push(
                    new SentryApm.Integrations.Tracing({
                        beforeNavigate: function (location) {
                            return location.pathname
                                .replace(/\d+/g, '<digits>')
                                .replace(/[a-f0-9]{32}/g, '<hash>');
                        },
                    }),
                );
                sentryConfig.tracesSampleRate = body.data(
                    'sentry-apm-sample-rate',
                );
            }

            if (body.data('sentry-replay-enabled')) {
                sentryConfig.integrations.push(new Sentry.Replay());
                sentryConfig.replaysSessionSampleRate = 1.0;
            }

            Sentry.init(sentryConfig);
            Sentry.configureScope(function (scope) {
                scope.setUser({ email: body.data('user-email') });
            });
        }

        bindhq.MIN_SEARCH_SCORE = body.data('min-search-score') || '0';
        bindhq.mailpane.init();
        bindhq.initContainer(this.element);

        $(window)
            .resize(bindhq.modalAjax.disableDropDown)
            .scroll(onWindowScroll);

        onWindowScroll();

        bindhq.dnd.init();
        bindhq.tasks.init();
        bindhq.itemActions.init();
        bindhq.divisionSwitcher.init();

        if (bindhq.editor) {
            $('.editor').initEach(bindhq.editor.initContainer);
        }

        $('.tablesorter').tablesorter({
            textExtraction: bindhq.util.tableSortText,
        });

        // Init copy button with success message
        function setMessage(btn, message) {
            $(btn).html(message);
        }

        function unsetMessage(btn, message) {
            setTimeout(function () {
                $(btn).html('<i class="fas fa-copy"></i> Copy');
            }, 1000);
        }

        const clipboard = new Clipboard('.clippy');

        clipboard.on('success', function (e) {
            setMessage(e.trigger, '<i class="fas fa-check"></i> Copied!');
            unsetMessage(e.trigger);
        });

        clipboard.on('error', function (e) {
            setMessage(e.trigger, '<i class="fas fa-times"></i> Failed!');
            unsetMessage(e.trigger);
        });

        body.addClass('js-loaded');

        this.#purgeLegacyCache();

        this.element.addEventListener(
            'autocomplete:pre-connect',
            this.#onAutocompletePreConnect,
        );
    }

    /**
     * Clean up legacy BindHQ frontend cache keys from LocalStorage, this
     * can be removed at some point when most users are likely to have
     * had it run...
     */
    #purgeLegacyCache() {
        if (Math.random() > 0.05) {
            return;
        }

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            if (key.match(/^bindhq-\d+/)) {
                localStorage.removeItem(key);
            }
        }
    }

    /**
     * @param {Object} event
     */
    #onAutocompletePreConnect(event) {
        if (!event.detail.options.onChange) {
            event.detail.options.onChange = TomSelectEvents.onChangeValidate;
        }
    }
}
