import { Controller } from '@hotwired/stimulus';
import { InsuredSelector } from '../../js/BindHQ/Application/InsuredSelector';
import { isEmptyAndNotDisabledSelect2 } from '../../js/BindHQ/Validation/isEmptyAndNotDisabledSelect2';
import { NextButton } from '../../js/BindHQ/Application/NextButton';

/**
 * @param {jQuery} agency
 */
function updateAgencyLink(agency) {
    const agencyId = agency.val();
    const agencyLink = $('.agency-link')
        .empty()
        .addClass('agency-link-placeholder');

    if (agencyId !== '') {
        const anchor = $('<a></a>')
            .html('View Agency &raquo;')
            .attr('href', '/secure/admin/agencies/' + agencyId)
            .attr('class', 'agency-link')
            .attr('target', '_blank');

        agencyLink.removeClass('agency-link-placeholder').append(anchor);
    }
}

/**
 * @param {jQuery} agency
 * @param {jQuery} agent
 */
function agencyChanged(agency, agent) {
    updateAgencyLink(agency);
}

export default class extends Controller {
    $producer = null;

    $insured = null;

    $agency = null;

    $agent = null;

    $effectiveDate = null;

    $lob = null;

    lobMapping = {};

    /**
     *
     */
    bindDOM() {
        this.$insured = $('#application_insured', this.element);
        this.$agency = $('#application_agency', this.element);
        this.$agent = $('#application_agent', this.element);
        this.$producer = $('#application_producer', this.element);
        this.$effectiveDate = $('#application_termEffective', this.element);
        this.$lob = $('#application_linesOfBusiness', this.element);
    }

    /**
     *
     */
    resetErrorState() {
        const requiredFields = this.getRequiredFields();

        for (const field of requiredFields) {
            field.parent().find('.select2-container').removeClass('error');

            $('[data-focus="' + field.attr('id') + '"]').removeClass(
                'text-danger',
            );
        }
    }

    /**
     * @return {Array}
     */
    getRequiredFields() {
        return [
            this.$producer,
            this.$agency,
            this.$agent,
            this.$insured,
            this.$lob,
            this.$effectiveDate,
        ];
    }

    /**
     *
     */
    onSubmit() {
        this.resetErrorState();

        let error = false;

        const requiredFields = this.getRequiredFields();

        for (const field of requiredFields) {
            if (isEmptyAndNotDisabledSelect2(field)) {
                error = true;
                field.parent().find('.select2-container').addClass('error');
                $('[data-focus="' + field.attr('id') + '"]').addClass(
                    'text-danger',
                );
            }
        }

        if (error) {
            return false;
        }
    }

    /**
     *
     */
    focusOnClick() {
        $('[data-focus]', this.element).on('click', function () {
            const $elem = $('#' + $(this).data('focus'));
            if ($elem.hasClass('select2') || $elem.hasClass('ajax-select')) {
                $elem.select2('open');
            } else {
                $elem.focus();
            }
        });
    }

    static targets = ['nextButton', 'saveButton'];

    static values = {
        supportedLinesOfBusiness: Object,
        defaultNextText: String,
    };

    connect() {
        const container = $(this.element);

        this.bindDOM();

        new InsuredSelector(container);

        $(this.element).on('submit', () => this.onSubmit());

        this.focusOnClick();
        this.initNextButton();
        this.initAgencyAndAgent();
    }

    initNextButton() {
        const nextButton = new NextButton(
            this.supportedLinesOfBusinessValue,
            $(this.nextButtonTarget),
            this.defaultNextTextValue,
            this.saveButtonTargets,
        );

        this.$lob.on('change', (evt) => nextButton.update(this.$lob.val()));

        nextButton.update(this.$lob.val());
    }

    initAgencyAndAgent() {
        this.$agency.on(
            'change',
            _.partial(agencyChanged, this.$agency, this.$agent),
        );

        if (this.$agency.val()) {
            updateAgencyLink(this.$agency);
        }
    }
}
