const BillingTypeListenerEvents = {
    CHANGED: 'bindhq.quote.billing_type.changed',
};

const Observable = require('../Observable');

class BillingTypeListener extends Observable {
    /**
     * @param {jQuery} container
     * @param {String} premiumLineItemType
     * @param {String} mgaFeeLineItemType
     * @param {String} carrierFeeLineItemType
     * @param {String} directBillBillingType
     * @param {Boolean} directBillMgaFeeEnabled
     */
    constructor(
        container,
        premiumLineItemType,
        mgaFeeLineItemType,
        carrierFeeLineItemType,
        directBillBillingType,
        directBillMgaFeeEnabled,
    ) {
        super();

        this.premiumLineItemType = premiumLineItemType;
        this.mgaFeeLineItemType = mgaFeeLineItemType;
        this.carrierFeeLineItemType = carrierFeeLineItemType;
        this.directBillBillingType = directBillBillingType;
        this.directBillMgaFeeEnabled = directBillMgaFeeEnabled;

        this.billingType = container.findOne('select.billing-type');

        this.taxesCollectedBy = container.findOne(
            'select#quote_details_taxesCollectedBy',
        );
        this.manualTaxes = container.findOne('.manual-taxes');
        this.lineItemContainer = container.findOne('.line-item-container');
    }

    init() {
        this.billingType.on('change', () => this.onChange());
        this.#update();
    }

    onChange() {
        this.#update();

        super.fire(BillingTypeListenerEvents.CHANGED);
    }

    #updateTaxesCollectedBy() {
        if ('direct_bill' == this.billingType.val()) {
            // always select marketing company for direct bill
            this.taxesCollectedBy.select2('val', 3);
            this.taxesCollectedBy.select2('readonly', true);
        } else {
            this.taxesCollectedBy.select2('readonly', false);
        }
    }

    #update() {
        if (this.billingType.prop('disabled')) {
            return;
        }

        if (this.directBillBillingType === this.billingType.val()) {
            this.#disableFeesAndTaxes();
        } else {
            this.#enableFeesAndTaxes();
        }

        this.#updateTaxesCollectedBy();
    }

    #disableFeesAndTaxes() {
        this.manualTaxes.prop('checked', false).prop('disabled', true);

        this.lineItemContainer
            .find('.collection-item')
            .each((index, collectionElement) => {
                const item = $(collectionElement);
                const type = item.findOne('select.line-item-type');
                const isPremium = this.premiumLineItemType === type.val();
                const isMgaFee = this.mgaFeeLineItemType === type.val();
                const isCarrierFee = this.carrierFeeLineItemType === type.val();

                if (isMgaFee && this.directBillMgaFeeEnabled) {
                    return;
                }

                if (isPremium || isCarrierFee) {
                    return;
                }

                item.remove();
            });
    }

    #enableFeesAndTaxes() {
        this.manualTaxes.prop('disabled', false);
    }
}

module.exports = { BillingTypeListener, BillingTypeListenerEvents };
