import { Controller } from '@hotwired/stimulus';

const {
    TermExpiryCalculator,
} = require('../../assets/js/BindHQ/Utils/TermExpiryCalculator');
const {
    PolicyTermGroup,
} = require('../../assets/js/BindHQ/Utils/PolicyTermGroup');

export default class extends Controller {
    connect() {
        const container = $(this.element);
        const termEffective = container.findOne('.term-effective');
        const termExpiration = container.findOne('.term-expiry');
        const policyTermCode = container.findOne('select.policy-term-code');
        const policyTermGroup = new PolicyTermGroup(
            new TermExpiryCalculator(),
            termEffective,
            termExpiration,
            policyTermCode,
        );

        policyTermGroup.init();
    }
}
