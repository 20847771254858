import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'existingFile',
        'file',
        'filename',
        'previewWrapper',
        'noPreviewWrapper',
        'unpreviewableWrapper',
        'uploadWrapper',
        'downloadableWrapper',
        'downloadable',
    ];

    static values = {
        existingFile: Object,
        previewables: Array,
    };

    connect() {
        this.#previewExistingFile();
    }

    onFileChange() {
        this.#preview();
    }

    onFileDeleteClick(event) {
        event.stopPropagation();
        event.preventDefault();

        window.dispatchEvent(
            new CustomEvent('confirm--button:clicked', {
                detail: {
                    title: 'Confirm',
                    body: 'Do you want to delete this file? All unsaved changes will be lost.',
                    cancel: 'Cancel',
                    confirm: 'Are you sure?',
                    confirmType: 'danger',
                    onConfirm: () => {
                        this.existingFileTarget.classList.replace(
                            'd-block',
                            'd-none',
                        );
                        this.previewWrapperTarget.classList.replace(
                            'd-flex',
                            'd-none',
                        );
                        this.noPreviewWrapperTarget.classList.replace(
                            'd-none',
                            'd-flex',
                        );
                        this.unpreviewableWrapperTarget.classList.replace(
                            'd-flex',
                            'd-none',
                        );
                        this.downloadableWrapperTarget.classList.add('d-none');
                        this.downloadableTarget.setAttribute('href', '#');
                        this.uploadWrapperTarget.classList.remove('d-none');
                        this.fileTarget.value = '';

                        window.dispatchEvent(
                            new CustomEvent('confirm--dialog:closeDialog'),
                        );
                    },
                    lockOnConfirm: false,
                },
            }),
        );
    }

    #preview() {
        if (this.fileTarget.files.length === 0) {
            return;
        }

        const filename = this.fileTarget.value.replace('C:\\fakepath\\', '');
        this.filenameTarget.innerHTML = filename;

        const file = this.fileTarget.files[0];

        this.existingFileTarget.classList.replace('d-none', 'd-block');
        this.uploadWrapperTarget.classList.add('d-none');

        if (!this.#isPreviewable(file.type)) {
            this.unpreviewableWrapperTarget.classList.replace(
                'd-none',
                'd-flex',
            );
            this.downloadableWrapperTarget.classList.add('d-none');
            this.downloadableTarget.setAttribute('href', '#');
            this.noPreviewWrapperTarget.classList.replace('d-flex', 'd-none');
            return;
        }

        const embedElement = file.type.startsWith('image/')
            ? document.createElement('img')
            : document.createElement('embed');

        embedElement.src = URL.createObjectURL(file);
        embedElement.classList.add('preview-embed');

        if (file.type === 'application/pdf') {
            embedElement.width = '100%';
            embedElement.height = '100%';
        }

        this.previewWrapperTarget.innerHTML = '';
        this.previewWrapperTarget.appendChild(embedElement);

        this.previewWrapperTarget.classList.replace('d-none', 'd-flex');
        this.noPreviewWrapperTarget.classList.replace('d-flex', 'd-none');
    }

    #previewExistingFile() {
        if (!this.hasExistingFileValue) {
            return;
        }

        const file = this.existingFileValue;

        if (!this.#isPreviewable(file.type)) {
            this.unpreviewableWrapperTarget.classList.replace(
                'd-none',
                'd-flex',
            );
            this.downloadableWrapperTarget.classList.remove('d-none');
            this.downloadableTarget.setAttribute(
                'href',
                `/secure/files/${file.id}/${file.name}`,
            );
            this.noPreviewWrapperTarget.classList.replace('d-flex', 'd-none');
            return;
        }

        const embedElement = file.type.startsWith('image/')
            ? document.createElement('img')
            : document.createElement('embed');

        embedElement.src = `/secure/files/${file.id}/${file.name}`;
        embedElement.title = file.name;
        embedElement.classList.add('preview-embed');

        if (file.type === 'application/pdf') {
            embedElement.width = '100%';
            embedElement.height = '100%';
        }

        this.previewWrapperTarget.innerHTML = '';
        this.previewWrapperTarget.appendChild(embedElement);

        this.uploadWrapperTarget.classList.add('d-none');
        this.existingFileTarget.classList.replace('d-none', 'd-block');
        this.previewWrapperTarget.classList.replace('d-none', 'd-flex');
        this.noPreviewWrapperTarget.classList.replace('d-flex', 'd-none');
    }

    #isPreviewable(type) {
        return this.previewablesValue.includes(type);
    }
}
